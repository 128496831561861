import React, { useState } from "react";
import axios from "axios";
import { baseurl } from "../../utils/BaseUrl";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify";

export default function Blog() {
	const [title, setTitle] = useState("");
	const [language, setLanguage] = useState("");
	const [keywords, setKeywords] = useState(["", "", ""]);
	const [imageUrl, setImageUrl] = useState("");
	const [loading, setLoading] = useState(false);
	// State to track if user is typing
	const [typingTimeout, setTypingTimeout] = useState(0);

	// Debounce function
	const debounceSearch = (text) => {
		clearTimeout(typingTimeout);
		const timeout = setTimeout(async () => {
			try {
				const res = await axios.post(`${baseurl}/api/pdf/genrateImage`, {
					prompt: text,
				});
				setImageUrl(res.data);
			} catch (error) {
				console.error("Error fetching image URL:", error);
			}
		}, 1000); // Adjust the delay time (in milliseconds) as needed
		setTypingTimeout(timeout);
	};

	// Handle input change for title
	const handleTitleChange = (e) => {
		setTitle(e.target.value);
		clearTimeout(typingTimeout); // Clear timeout on new input
		const timeout = setTimeout(() => {
			debounceSearch(e.target.value);
		}, 1000); // Adjust the delay time (in milliseconds) as needed
		setTypingTimeout(timeout);
	};

	// Function to handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			const response = await axios.post(
				`${baseurl}/api/CreateBlog/CreateBlog`,
				{
					topic: title,
					language,
					keywords: keywords.filter((keyword) => keyword.trim() !== ""),
					imageUrl,
				},
			);
			console.log(response.data);
			saveAs(`${baseurl}/uploads/${response.data.path}`);
			toast.success("Blog created successfully");
		} catch (error) {
			console.error("Error creating blog:", error);
		} finally {
			setLoading(false);
		}
	};

	// Render method returns the JSX structure of the component
	return (
		<div className='min-h-screen bg-zinc-50 dark:bg-zinc-900 flex flex-col items-center py-10'>
			<ToastContainer />
			<header className='w-full max-w-4xl flex justify-between items-center px-4 sm:px-6 lg:px-8 mb-8'>
				<h1 className='text-2xl font-bold text-blue-600'>SEO</h1>
				<nav className='flex space-x-4'>
					<a href='#' className='text-sm text-zinc-700 dark:text-zinc-300'>
						Help
					</a>
					<a href='#' className='text-sm text-zinc-700 dark:text-zinc-300'>
						Account
					</a>
				</nav>
			</header>
			<main className='w-full max-w-4xl bg-white dark:bg-zinc-800 rounded-lg shadow-md p-6'>
				<h2 className='text-xl font-semibold mb-4'>
					Generate Articles just by giving Title, language and keywords
				</h2>
				<section className='bg-white dark:bg-zinc-800 p-6 rounded-lg shadow-md'>
					<h3 className='text-lg font-semibold mb-4'>Create an Article</h3>
					<form onSubmit={handleSubmit} className='space-y-4'>
						<div>
							<label
								htmlFor='title'
								className='block text-sm font-medium text-zinc-700 dark:text-zinc-300'>
								Topic Name
							</label>
							<input
								type='text'
								id='title'
								placeholder='Enter topic here'
								value={title}
								onChange={handleTitleChange}
								className='mt-1 block w-full p-2 border border-zinc-300 dark:border-zinc-700 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-zinc-700 dark:text-zinc-300'
							/>
						</div>
						<div>
							<label
								htmlFor='language'
								className='block text-sm font-medium text-zinc-700 dark:text-zinc-300'>
								Language
							</label>
							<input
								type='text'
								placeholder='enter language'
								id='language'
								value={language}
								onChange={(e) => setLanguage(e.target.value)}
								className='mt-1 block w-full p-2 border border-zinc-300 dark:border-zinc-700 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-zinc-700 dark:text-zinc-300'
							/>
						</div>
						<div>
							<label
								htmlFor='keywords'
								className='block text-sm font-medium text-zinc-700 dark:text-zinc-300'>
								Keywords
							</label>
							<input
								type='text'
								id='keyword1'
								placeholder='Keyword 1'
								value={keywords[0]}
								onChange={(e) =>
									setKeywords([e.target.value, ...keywords.slice(1)])
								}
								className='mt-1 block w-full p-2 border border-zinc-300 dark:border-zinc-700 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-zinc-700 dark:text-zinc-300'
							/>
							<input
								type='text'
								id='keyword2'
								placeholder='Keyword 2'
								value={keywords[1]}
								onChange={(e) =>
									setKeywords([
										keywords[0],
										e.target.value,
										...keywords.slice(2),
									])
								}
								className='mt-1 block w-full p-2 border border-zinc-300 dark:border-zinc-700 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-zinc-700 dark:text-zinc-300'
							/>
							<input
								type='text'
								id='keyword3'
								placeholder='Keyword 3'
								value={keywords[2]}
								onChange={(e) =>
									setKeywords([...keywords.slice(0, 2), e.target.value])
								}
								className='mt-1 block w-full p-2 border border-zinc-300 dark:border-zinc-700 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-zinc-700 dark:text-zinc-300'
							/>
						</div>
						<button
							type='submit'
							style={{ backgroundColor: "blue" }}
							className='w-40 bg-blue-600 text-white py-2 mt-6 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
							{loading ? "creating Blog..." : "create article"}
						</button>
					</form>
				</section>
			</main>
		</div>
	);
}
